<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-search-box @enter="getSystems">
          <template slot="search">
            <div class="col-6">
              <!-- 사업장 -->
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
            </div>
            <div class="col-6">
              <!-- 사용여부 -->
              <c-select
                :comboItems="useFlagItems"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="useFlag"
                label="사용여부"
                v-model="searchParam.useFlag"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <!--체크리스트 시스템요소 목록-->
        <c-table
          ref="systemTable"
          title="체크리스트 시스템요소 목록"
          tableId="systemTable"
          :columns="systemGrid.columns"
          :data="systemGrid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable" label="" icon="add" @btnClicked="addRowSystem" />
              <!--저장-->
              <c-btn 
                v-if="editable" 
                :isSubmit="isSaveSystem"
                :url="saveSystemUrl"
                :param="systemGrid.data"
                mappingType="PUT"
                label="" 
                icon="save"
                @beforeAction="saveSystem"
                @btnCallback="saveSystemCallback" />
              <!--검색-->
              <c-btn label="" icon="search" @btnClicked="getSystems" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip outline square
                v-if="props.row.editFlag !== 'C'"
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="getChecklist(props.row.saiInternalAuditChecklistSystemId)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <!--체크리스트 항목 목록-->
        <c-table
          ref="checkGrid"
          title="체크리스트 항목 목록"
          tableId="checkGrid"
          :columnSetting="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="checkGrid.columns"
          :data="checkGrid.data"
        >
          <!-- selection="multiple"
          rowKey="saiInternalAuditChecklistId" -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable && selectSystemId" label="추가" icon="add" @btnClicked="addCheck" />
              <!-- <c-btn v-if="editable && selectSystemId" label="삭제" icon="remove" @btnClicked="deleteCheck" /> -->
              <!--저장-->
              <c-btn v-if="editable && selectSystemId" label="저장" icon="save" @btnClicked="saveCheck" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internalChecklist',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y'
      },
      systemGrid: {
        columns: [
          {
            name: 'plantCd',
            field: 'plantCd',
            //사업장
            label: '사업장',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:80px',
            type: 'plant',
          },
          {
            required: true,
            name: 'systemElementsName',
            field: 'systemElementsName',
            //시스템요소
            label: '시스템요소',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            //사용여부
            label: '사용여부',
            align: 'center',
            style: 'width:40px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            //순서(순번)
            label: '정렬순서',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            type: 'number',
          },
          {
            name: 'customCol',
            field: 'customCol',
            //항목
            label: '항목',
            align: 'center',
            style: 'width:50px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      checkGrid: {
        columns: [
          {
            name: 'itemName',
            field: 'itemName',
            //내부심사 항목
            label: '내부심사 항목',
            align: 'left',
            sortable: true,
            type: 'textarea'
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            //내부심사 시 착안사항
            label: '내부심사 시 착안사항',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'textarea'
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            //순서(순번)
            label: '정렬순서',
            type: 'number',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            //사용여부
            label: '사용여부',
            type: 'check',
            sortable: true,
            align: 'center',
            style: 'width:80px',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      selectSystemId: '',
      checklist: {
        saiInternalAuditChecklistId: '',  // 내부감사 체크리스트 항목 일련번호
        saiInternalAuditChecklistSystemId: '',  // 내부감사 체크리스트 시스템 요소 일련번호
        itemName: '',  // 내부심사 항목
        aimMatter: '',  // 내부심사 시 착안 사항
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      mappingType: 'POST',
      saveMode: false,
      isSaveSystem: false,
      isSaveCheck: false,
      listSystemUrl: '',
      saveSystemUrl: '',
      listCheckUrl: '',
      detailCheckUrl: '',
      saveCheckUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    showDetail() {
      return this.selectSystemId // 부모가 선택되어진 경우
    },
    detailDisabled() {
      return !this.showDetail || !this.saveMode
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listSystemUrl = selectConfig.sai.internal.base.systemChecklist.list.url;
      this.saveSystemUrl = transactionConfig.sai.internal.base.systemChecklist.save.url;
      this.listCheckUrl = selectConfig.sai.internal.base.checklist.list.url;
      this.detailCheckUrl = selectConfig.sai.internal.base.checklist.get.url;
      this.saveCheckUrl = transactionConfig.sai.internal.base.checklist.update.url;
      // code setting
      // list setting
      this.getSystems();
    },
    getSystems() {
      this.checkGrid.data = [];
      this.$http.url = this.listSystemUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.systemGrid.data = _result.data;
      },);
    },
    getChecklist(_id) {
      this.$set(this.$data, 'selectSystemId', _id)
      this.saveMode = false;
      this.$http.url = this.listCheckUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalAuditChecklistSystemId: _id
      };
      this.$http.request((_result) => {
        this.checkGrid.data = _result.data;
        this.reset();
      },);
    },
    reset() {
      this.saveMode = false;
      Object.assign(this.$data.checklist, this.$options.data().checklist);
    },
    addRowSystem() {
      this.reset();
      this.systemGrid.data.splice(0, 0, {
        saiInternalAuditChecklistSystemId: uid(),  // 내부감사 체크리스트 시스템 요소 일련번호
        plantCd: null,  // 사업장코드
        systemElementsName: '',  // 시스템 요소
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      })
    },
    saveSystem() {
      if (this.$comm.validTable(this.systemGrid.columns, this.systemGrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',    // 확인
          message: '저장하시겠습니까?', // 저장하시겠습니까?     // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.systemGrid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });

            this.isSaveSystem = !this.isSaveSystem
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSystemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSystems();
      this.reset();
    },
    addCheck() {
      this.checkGrid.data.push({
        saiInternalAuditChecklistId: uid(),  // 내부감사 체크리스트 항목 일련번호
        saiInternalAuditChecklistSystemId: this.selectSystemId,  // 내부감사 체크리스트 시스템 요소 일련번호
        itemName: '',  // 내부심사 항목
        aimMatter: '',  // 내부심사 시 착안 사항
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveCheck() {
      if (this.$comm.validTable(this.checkGrid.columns, this.checkGrid.data)) {
        let saveData = this.checkGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',  // 확인
            message: '저장하시겠습니까?', // 저장하시겠습니까?   // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveCheckUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getChecklist(this.selectSystemId)
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', /* 안내 */
            message: '변경된 데이터가 없습니다.', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteCheck() {
      let selectData = this.$refs['checkGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',  // 확인
          message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.saveCheckUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.checkGrid.data = this.$_.reject(this.checkGrid.data, item);
              })
              this.$refs['checkGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }
  }
};
</script>
